
import KTLoader from "@/components/Loader.vue";
import { defineComponent, onMounted, ref } from "vue";
// import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
// import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
// import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
// import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
// import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
// import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
// import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
// import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
// import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
// import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { config } from "@/core/helpers/config";

export default defineComponent({
  name: "dashboard",
  components: {
    // TableWidget9,
    // TableWidget5,
    // ListWidget1,
    // ListWidget2,
    // ListWidget3,
    // ListWidget5,
    // ListWidget6,
    // MixedWidget2,
    // MixedWidget5,
    // MixedWidget7,
    // MixedWidget10,
    Datepicker,
    StatisticsWidget5,
    KTLoader
  },
  setup() {
    const showData = ref(false);
    const date = ref();
    const startDate = ref("");
    const endDate = ref("");
    const userType = ref("");
    const store = useStore();
    const countOfData = ref([]);

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      const data = localStorage.getItem("userdata");
      if (data) {
        var allData = JSON.parse("" + data + "");
        userType.value = allData.type;
      }
      const endDate = new Date();
      const startDate = new Date(new Date().setDate(endDate.getDate() - 0));
      date.value = [startDate, endDate];
      format(date.value);
      showData.value = false
      countData();
    });

    const changesdate = (data) => {
      console.log("df", data);
      showData.value = false
      setTimeout(() => {
        countData();
      }, 500)
      
    };

    const countData = () => {
      
      var request = {
        url: `dashboardData/user`,
        data: {
          type: userType.value,
          startDate: startDate.value,
          endDate: endDate.value,
        },
      };
      store.dispatch(Actions.POST, request).then((data) => {
        if (data) {
          countOfData.value = data.data;
          //setTimeout(() => {
            showData.value = true
          //}, 1000)
          
          // console.log(data)
        }
      });
    };

    const format = (date) => {
      var startdate = date[0];
      var enddate = date[1];
      console.log(startdate);
      console.log(enddate);
      let day = startdate.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      let month = startdate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      const year = startdate.getFullYear();

      let day1 = enddate.getDate();
      if (day1 < 10) {
        day1 = `0${day1}`;
      }

      let month1 = enddate.getMonth() + 1;
      if (month1 < 10) {
        month1 = `0${month1}`;
      }

      const year1 = enddate.getFullYear();
      startDate.value = `${year}-${month}-${day}`;
      endDate.value = `${year1}-${month1}-${day1}`;
      date.value = `${day}/${month}/${year} - ${day1}/${month1}/${year1}`;
      return date.value;
    };
    const loaderEnabled = ref(true);
    return {
      countData,
      countOfData,
      userType,
      date,
      changesdate,
      format,
      // datechanges
      showData,
      loaderEnabled
    };
  },
});
