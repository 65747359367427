import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("a", {
    href: "javascript:void(0)",
    class: _normalizeClass([[_ctx.widgetClasses, `bg-${_ctx.color}`], "card hoverable"]),
    style: {"cursor":"default"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.svgIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([`svg-icon-${_ctx.iconColor}`, "svg-icon svg-icon-3x mx-n1"])
          }, [
            _createVNode(_component_inline_svg, { src: _ctx.svgIcon }, null, 8, ["src"])
          ], 2))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createElementVNode("i", {
              class: _normalizeClass(`bi bi-${_ctx.icon} text-white fs-2x`)
            }, null, 2)
          ])),
      _createElementVNode("div", {
        class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-7 mb-2 mt-5"])
      }, _toDisplayString(_ctx.available), 3),
      _createElementVNode("div", {
        class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-2 mb-2 mt-5"])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bold fs-7"])
      }, _toDisplayString(_ctx.description), 3)
    ])
  ], 2))
}